<template>
  <div class='box'>
    <div class="hearde">
      <div class='imageBox'>
        <img class='image' src="../src/assets/img/qudaowuyou.png" alt="">
      </div>
    </div>
    <div class="center">

    </div>
    <div class="footer">
    <div class="footer-content">
        <div style='font-size: 9px;color: #FFFFFF;margin-bottom:0.94rem'>联系我们</div>
        <div style='font-size: 9px;color: #FFFFFF;margin-bottom:0.94rem'>广州渠道无忧网络技术服务有限公司</div>
        <div style='font-size: 8px;color: #A6A7AA;margin-bottom:0.94rem'>电话：020-29884285 / 18026284260</div>
        <div style='font-size: 8px;color: #A6A7AA;margin-bottom:0.94rem'>QQ：1164290938</div>
        <div style='font-size: 8px;color: #A6A7AA;margin-bottom:0.94rem'>微信：wangdiannaijing</div>
        <div style='font-size: 8px;color: #A6A7AA;margin-bottom:0.94rem'>地址：广州市白云区机场路1962号国际单位一期e栋202</div>
    </div>
    <div class="line"></div>
    <div class="footer-bottom">
    <div style='font-size: 8px;color: #fff;margin-bottom:0.94rem'>Copyright © 2013-2019 qudaowuyou. All Rights Reserved 广州渠道无忧网络技术服务有限公司</div>
    <div style='font-size: 8px;color: #A6A7AA;margin-bottom:0.94rem'>粤公网安备 44011102000534号 <a href="https://beian.miit.gov.cn/" target="_blank" class='link'>粤ICP备13036846号</a></div>
    </div>
    </div>
    
  </div>
</template>

<script>
export default {

}
</script>

<style>
.box{
  height:67.5rem
}
.hearde{
  width: 100%;
  height: 7%;
  background-color:#fff;
  box-sizing:border-box;
  padding-top:13px
}
.imageBox{
   margin-left:19%;
}
.image{
  width:10%;
  height:3.8%
}
.center{
  width:100%;
  height:63%;
  background-image:url('../src/assets/img/bgc.png');
  background-repeat:no-repeat;
  background-size:100% 100%
}
.footer{
  width:100%;
  height:30%;
  background-color:#000;
  box-sizing:border-box;
  padding-top:1.6%;
  padding-left:18%;
}
.footer-content{
  width:21.4rem;
  height:11rem;
  margin-left:0.94rem;
  margin-bottom:0.94rem

}
.line{
  width:77.2rem;
  height:1px;
  background-color:#656973;
  margin-bottom:0.94rem
}
.footer-bottom{
  width:37.3rem;
  height:2.82rem;
  margin-left:18rem;
  text-align:center

}
.link{
  text-decoration: none;
  color:#A6A7AA
}
</style>